var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSidebar",
    {
      attrs: {
        aside: "",
        show: _vm.$store.state.menuSjabloonMakeLinkAsideShow,
        colorScheme: "light",
        overlaid: "",
        size: "lg",
      },
      on: {
        "update:show": (val) =>
          _vm.$store.commit("set", ["menuSjabloonMakeLinkAsideShow", val]),
      },
    },
    [
      _c("div", { staticClass: "d-flex my-1" }, [
        !_vm.showErrorAlert
          ? _c("div", { staticClass: "flex-grow-1" }, [
              typeof _vm.item.id !== "undefined" &&
              _vm.item.restaurant.restaurant_logo
                ? _c(
                    "div",
                    { staticClass: "logo-container" },
                    [
                      _c(
                        "CLink",
                        {
                          attrs: {
                            to: {
                              name: "View Restaurant",
                              params: { id: _vm.item.restaurant.id },
                            },
                            target: "_blank",
                          },
                        },
                        [
                          _c("CImg", {
                            staticClass: "rounded mx-auto restaurant-logo",
                            attrs: {
                              src: _vm.item.restaurant.restaurant_logo_path,
                              placeholderColor: "lightgray",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticStyle: { height: "56px" } },
          [
            _c("CSidebarClose", {
              nativeOn: {
                click: function ($event) {
                  return _vm.$store.commit(
                    "toggle",
                    "menuSjabloonMakeLinkAsideShow"
                  )
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm.showErrorAlert
        ? _c(
            "CListGroup",
            { staticClass: "list-group-accent mt-5" },
            [
              _c(
                "CListGroupItem",
                {
                  staticClass:
                    "list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase",
                },
                [_vm._v(" Error ")]
              ),
              _c(
                "CListGroupItem",
                {
                  staticClass:
                    "list-group-item-accent-danger list-group-item-divider",
                },
                [
                  _c(
                    "div",
                    { staticClass: "c-avatar float-left mr-2" },
                    [
                      _c("CIcon", {
                        staticClass: "c-avatar-img text-danger",
                        attrs: { name: "cil-bug", size: "xl" },
                      }),
                    ],
                    1
                  ),
                  _c("div", [_vm._v(_vm._s(_vm.errorAlertMessage))]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.showErrorAlert && !_vm.loading
        ? _c(
            "CListGroup",
            { staticClass: "list-group-accent" },
            [
              _c(
                "CListGroupItem",
                {
                  staticClass:
                    "list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase",
                },
                [
                  typeof _vm.item.id !== "undefined"
                    ? _c(
                        "CLink",
                        {
                          directives: [
                            {
                              name: "c-tooltip",
                              rawName: "v-c-tooltip",
                              value: {
                                content: this.$router.resolve({
                                  name: "View Restaurant",
                                  params: { id: _vm.item.restaurant.id },
                                }).href,
                              },
                              expression:
                                "{content: this.$router.resolve({name: 'View Restaurant',params: { id: item.restaurant.id }}).href }",
                            },
                          ],
                          attrs: {
                            to: {
                              name: "View Restaurant",
                              params: { id: _vm.item.restaurant.id },
                            },
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.item.restaurant.restaurant_name) +
                              " "
                          ),
                          _c("CIcon", {
                            staticClass: "ml-2 mb-1",
                            attrs: { name: "cil-external-link" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.successAlertMessage
                ? _c(
                    "CListGroupItem",
                    {
                      staticClass:
                        "list-group-item-accent-success table-success",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "c-avatar float-left align-items-baseline h-100 mr-2",
                        },
                        [
                          _c("CIcon", {
                            staticClass: "c-avatar-img text-success",
                            attrs: { name: "cil-check-alt", size: "xl" },
                          }),
                        ],
                        1
                      ),
                      _c("div", [_vm._v(_vm._s(_vm.successAlertMessage))]),
                    ]
                  )
                : _vm._e(),
              typeof _vm.item.id !== "undefined"
                ? _c(
                    "CListGroupItem",
                    { staticClass: "list-group-item-accent-primary" },
                    [
                      _c("div", [_c("h4", [_vm._v(_vm._s(_vm.item.name))])]),
                      _c(
                        "CRow",
                        [
                          _c("CCol", { attrs: { col: "12" } }, [
                            _c(
                              "h6",
                              { staticClass: "text-muted mr-3" },
                              [
                                _c("CIcon", {
                                  staticClass: "mr-2",
                                  attrs: { name: "cil-arrow-right" },
                                }),
                                _vm._v(" " + _vm._s(_vm.item.notes) + " "),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "CCol",
                            {
                              staticClass: "border-shadow mt-3 p-3",
                              attrs: { col: "12" },
                            },
                            [
                              _c(
                                "CForm",
                                { on: { submit: _vm.makeLink } },
                                [
                                  !_vm.loading &&
                                  _vm.showchk_rmcurrlinksdisabledmenus
                                    ? _c("CInputCheckbox", {
                                        staticClass: "mb-3",
                                        attrs: {
                                          label:
                                            "Remove current links from excluded menus",
                                          checked: _vm.remove_all_links,
                                          custom: "",
                                        },
                                        on: {
                                          "update:checked": function ($event) {
                                            _vm.remove_all_links = $event
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "CButton",
                                    {
                                      attrs: {
                                        type: "submit",
                                        color: "primary",
                                        size: "sm",
                                        shape: "pill",
                                        disabled:
                                          _vm.item.detail_ext_groups_count == 0,
                                      },
                                    },
                                    [
                                      _c("CIcon", {
                                        staticClass: "mb-1",
                                        attrs: { name: "cil-link" },
                                      }),
                                      _vm._v(" Make Link "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { opacity: 0.2 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }