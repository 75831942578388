var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("MakeLinkAside", { attrs: { id: _vm.itemId } }),
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _c(
        "CCard",
        { staticClass: "filters", attrs: { "accent-color": "warning" } },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-md-row flex-column",
                    staticStyle: { gap: "0.5rem" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-grow-1" },
                      [
                        _c("v-select", {
                          staticClass: "v-select-filter",
                          attrs: {
                            placeholder: "Select restaurants..",
                            multiple: "",
                            options: _vm.allRestaurants,
                          },
                          on: { input: _vm.restaurantsFilter },
                          model: {
                            value: _vm.selectedRestaurants,
                            callback: function ($$v) {
                              _vm.selectedRestaurants = $$v
                            },
                            expression: "selectedRestaurants",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.pages > 1
                      ? _c(
                          "div",
                          [
                            _c("CPagination", {
                              attrs: {
                                align: "end",
                                dots: false,
                                pages: _vm.pages,
                                "active-page": _vm.activePage,
                              },
                              on: {
                                "update:activePage": function ($event) {
                                  _vm.activePage = $event
                                },
                                "update:active-page": function ($event) {
                                  _vm.activePage = $event
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("CDataTable", {
        attrs: {
          striped: "",
          hover: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          sorter: { external: true, resetable: true },
          "table-filter": {
            label: "Filter:",
            placeholder: "...",
            external: true,
            lazy: true,
          },
          tableFilterValue: _vm.search,
          "column-filter": { external: true, lazy: true },
          itemsPerPageSelect: {
            external: true,
            values: [5, 15, 25, 50, 100, 250, 500],
          },
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No menu sjabloons found!",
          },
        },
        on: {
          "update:tableFilterValue": function ($event) {
            _vm.search = $event
          },
          "update:table-filter-value": [
            function ($event) {
              _vm.search = $event
            },
            _vm.tableSearch,
          ],
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:sorter-value": _vm.sorterValue,
          "pagination-change": _vm.paginationChange,
        },
        scopedSlots: _vm._u([
          {
            key: "select",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c("CInputCheckbox", {
                      attrs: { checked: item._selected, custom: "" },
                      on: { "update:checked": () => _vm.check(item) },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "id",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CLink",
                      {
                        attrs: {
                          to: {
                            name: "Edit Menu Sjabloon",
                            params: {
                              restaurant_id: item.restaurant_id,
                              id: item.id,
                            },
                          },
                        },
                      },
                      [_c("strong", [_vm._v(_vm._s(item.id))])]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "make_link",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "CLink",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showMakeLinkAside(item.id)
                          },
                        },
                      },
                      [_vm._v(" 🔗 Make Link ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "restaurant_name",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CLink",
                      {
                        attrs: {
                          to: {
                            name: "View Restaurant",
                            params: { id: item.restaurant_id },
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(item.restaurant.restaurant_name) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "location",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(item.restaurant.restaurant_postcode) +
                      ", " +
                      _vm._s(item.restaurant.restaurant_city) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "categories",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  _vm._l(item.categories, function (c) {
                    return _c(
                      "span",
                      { key: c.id, staticClass: "badge-category table-info" },
                      [_vm._v(_vm._s(c.category_name))]
                    )
                  }),
                  0
                ),
              ]
            },
          },
          {
            key: "menu_sjabloon",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CLink",
                      {
                        attrs: {
                          to: {
                            name: "Edit Menu Sjabloon",
                            params: {
                              restaurant_id: item.restaurant_id,
                              id: item.id,
                            },
                          },
                        },
                      },
                      [
                        _c("strong", [_vm._v(_vm._s(item.name))]),
                        _vm._v(" "),
                        _c("em", [_vm._v("[" + _vm._s(item.notes) + "]")]),
                        _vm._v(" ("),
                        _c("strong", [
                          _vm._v(_vm._s(item.detail_ext_groups_count)),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("pluralize")(
                                "menugroup",
                                item.detail_ext_groups_count
                              )
                            ) +
                            ") "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item, index }) {
              return [
                _c(
                  "td",
                  { staticClass: "py-2 text-center" },
                  [
                    _c(
                      "CButton",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: "Make a copy",
                            expression: "'Make a copy'",
                          },
                        ],
                        attrs: {
                          size: "sm",
                          color: "success",
                          shape: "pill",
                          variant: "outline",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.copyItem(item, index)
                          },
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-copy" } })],
                      1
                    ),
                    _c(
                      "CButton",
                      {
                        staticClass: "mx-1",
                        attrs: {
                          size: "sm",
                          color: "info",
                          shape: "pill",
                          variant: "ghost",
                          to: {
                            name: "Edit Menu Sjabloon",
                            params: {
                              restaurant_id: item.restaurant_id,
                              id: item.id,
                            },
                          },
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-align-left" } })],
                      1
                    ),
                    _c(
                      "CButton",
                      {
                        attrs: {
                          size: "sm",
                          color: "danger",
                          shape: "pill",
                          variant: "ghost",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(item, index)
                          },
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-trash" } })],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "CModal",
        {
          staticClass: "replicate-menu-sjabloon",
          attrs: {
            title: "Replicate Menu Sjabloon",
            color: "success",
            show: _vm.replicateMenuSjabloonModal,
            closeOnBackdrop: false,
            centered: "",
          },
          on: {
            "update:show": function ($event) {
              _vm.replicateMenuSjabloonModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "link" },
                      on: {
                        click: function ($event) {
                          _vm.replicateMenuSjabloonModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: {
                        disabled: _vm.selectedRestaurant === null,
                        size: "sm",
                        color: "success",
                      },
                      on: { click: _vm.replicateMenuSjabloon },
                    },
                    [
                      _c("CIcon", { attrs: { name: "cil-clone" } }),
                      _vm._v(" Replicate "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "label",
                {
                  staticClass: "d-block",
                  staticStyle: { "font-weight": "500" },
                },
                [_vm._v("Target Restaurant:")]
              ),
              _c("v-select", {
                staticClass: "v-select-filter",
                attrs: {
                  placeholder: "Select restaurant..",
                  options: _vm.allRestaurants,
                },
                model: {
                  value: _vm.selectedRestaurant,
                  callback: function ($$v) {
                    _vm.selectedRestaurant = $$v
                  },
                  expression: "selectedRestaurant",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.total))]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "CButton",
                      {
                        attrs: {
                          color: "success",
                          size: "sm",
                          disabled: _vm.selectedItem === null,
                        },
                        on: {
                          click: function ($event) {
                            _vm.replicateMenuSjabloonModal = true
                          },
                        },
                      },
                      [
                        _c("CIcon", {
                          staticClass: "mb-1",
                          attrs: { name: "cil-clone" },
                        }),
                        _vm._v(" Replicate Menu Sjabloon "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.pages > 1
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("CPagination", {
                          attrs: {
                            align: "center",
                            dots: false,
                            pages: _vm.pages,
                            "active-page": _vm.activePage,
                          },
                          on: {
                            "update:activePage": function ($event) {
                              _vm.activePage = $event
                            },
                            "update:active-page": function ($event) {
                              _vm.activePage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }